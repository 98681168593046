<template>
  <div class="flex flex-row">
    <UFormGroup label="Rede" class="w-full" name="retail_chain.uuid">
      <USelectMenu
        v-model="form.retail_chain"
        :searchable="true"
        placeholder="Selecione uma rede"
        size="xl"
        label="Rede"
        :options="data"
        option-attribute="name"
        :loading="isPending"
        :popper="{
          adaptive: true,
          overflowPadding: 12,
          strategy: 'absolute',
        }"
        @change="$emit('change', $event)"
      />
    </UFormGroup>
  </div>
</template>

<script setup lang="ts">
import { useRetailChains } from "~/queries/retail-chains";

defineEmits(["change"]);

defineProps({
  form: {
    type: Object,
    required: true,
  },
});

const { data, isPending, suspense } = useRetailChains();

onMounted(() => {
  suspense();
});
</script>
