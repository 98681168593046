import { useQuery } from "@tanstack/vue-query";

export const  useRetailChains = () => {
  const axios = useAxios()

  const query = useQuery({
    queryKey: ["retail-chains"],
    queryFn: async () => {
      const { data } = await axios.get(`retail-chains?siv_contingency=1`);
      return data.data.retail_chains;
    },
  });

  return query
} 